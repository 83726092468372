import {
  AddressSchema,
  DraftReturnSchema,
} from "@redotech/redo-model/draft-return/draft-return";
import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const createDraftReturn = {
  input: z.object({
    orderId: zExt.objectId().nullish(),
    email: z.string().nullish(),
    address: AddressSchema.nullish(),
    returnType: z.nativeEnum(ReturnTypeEnum),
    customerTimezone: z.string().nullish(),
  }),
  output: z.object({ draftReturn: DraftReturnSchema }),
};

export const createOrderlessDraftReturn = {
  input: z.object({
    productIds: z.array(z.string()),
    warrantyId: z.string().nullish(),
    returnType: z.nativeEnum(ReturnTypeEnum),
    isThirdPartyClaim: z.boolean().optional(),
  }),
  output: z.object({ draftReturn: DraftReturnSchema }),
};

export const addProductToOrderlessDraftReturn = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    productIds: z.array(z.string()),
  }),
  output: z.object({ draftReturn: DraftReturnSchema }),
};
